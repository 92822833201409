import React from "react";
import { Tooltip, Grid, Box } from "@mui/material";
import timeStamp_toString_Complete from "../../Utils/timeStamp_toString_Complete";

const Dates = ({ fechaServicio, fecha, widthIMG }) => {
  const LABELS_SERVDA = {
    laboratorio:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png",
    radiologia:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Rayos_X.png",
    administrador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Call_center.png",
    operador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
    pmr: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/PMR.png",
    resonancia:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Resonancia.png",
    tomografia:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Tomografia.png",
    ultrasonido:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ultrasonido.png",
    consulta:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/ConsultaMedica.png",
  };
  if (!fecha) {
    return (
      <>
        <Grid item>
          <Tooltip arrow title={"Laboratorio" || "Verificar información"}>
            <Box
              component="img"
              src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png"
              alt="Laboratorio"
              // 64 x 173
              width={widthIMG}
              height={widthIMG}
            />
          </Tooltip>
          {timeStamp_toString_Complete(fechaServicio)} hrs.
          <br />
        </Grid>
      </>
    );
  }
  return Object.entries(fecha).map(([key, value]) => (
    <>
      <Tooltip arrow title={key || "Verificar información"}>
        <Box
          component="img"
          src={LABELS_SERVDA[key]}
          alt={LABELS_SERVDA[key]}
          // 64 x 173
          width={widthIMG}
          height={widthIMG}
        />
      </Tooltip>
      {timeStamp_toString_Complete(value)} hrs. <br />
    </>
  ));
};

export default Dates;
